<script setup lang="ts">
import { mapProducts } from "~/utils/mapProducts"
import { NotificationAlertProps } from "~/components/NotificationAlert.props"
import { customPaths } from "~/utils/constants"
import { checkEnvSite } from "~/server/utils/getAlgoliaConfig"

const { preCheckoutProducts } = usePreCheckout()
const algoliaIndex = useAlgoliaInitIndex(`TOP_PRODUCTS${checkEnvSite}`)
const { totalAmoutOrder } = useCart()
const { t } = useI18n()
const { addNotification } = useNotification()

const findItemsFromAlgolia = async (productCodes: string[]) => {
  return algoliaIndex.getObjects(productCodes.map((code) => `product_${code}`))
}

const { data: selectedProducts } = await useAsyncData(
  "pre-checkout-products",
  async () =>
    preCheckoutProducts.value
      ? findItemsFromAlgolia(preCheckoutProducts.value)
      : { results: [] },
  {
    watch: [preCheckoutProducts],
    transform: (items) => mapProducts(items?.results ?? [], null, "ALGOLIA")
  }
)
const modalCta = computed(() => ({
  infoText: t("dialogsAndModals.addToCart.total"),
  price: totalAmoutOrder.value,
  cta: {
    path: customPaths.checkout,
    text: t("dialogsAndModals.addToCart.goToPay")
  }
}))

const handleAddToCartNotification = (productName: string) => {
  const notification: NotificationAlertProps = {
    id: Math.random(),
    type: "success",
    notificationAlertText: t(
      "dialogsAndModals.addToCart.notificationAddToCart",
      { productName: productName }
    ),
    isWithCloseIcon: true
  }

  addNotification(notification)
}
</script>

<template>
  <DialogsAndModalsUtilsModal
    id="pre-checkout"
    :enable-on-click-outside="false"
    fullscreen
  >
    <OrganismsCartModalsProducts
      @on-close="navigateTo(customPaths.checkout)"
      @notification="handleAddToCartNotification($event)"
      :title="$t('dialogsAndModals.addToCart.needMore')"
      :text="$t('dialogsAndModals.addToCart.selectedProducts')"
      :products="
        selectedProducts?.filter((item) => item?.inStock === true)?.slice(0, 3)
      "
      :cta="modalCta"
    />
  </DialogsAndModalsUtilsModal>
</template>
