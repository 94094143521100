<script setup lang="ts">
import { ProductAlgolia } from "utils/mapProducts"
import { AddProductsProps } from "./AddProducts.props"

const { closeModal } = useModal()

const similarProductCode = useState("similar-product-code")

defineProps<AddProductsProps>()

const emit =
  defineEmits<{
    (e: "notification", productName: string): void
  }>()

const { data: result } = useLazyFetch(
  `/api/algolia/getRecommendations/recommend-9/${similarProductCode.value}`,
  {
    server: false
  }
)

const similarProducts = computed(() => {
  const products: ProductAlgolia[] =
    _flattenDeep(result.value?.results?.map((item) => item.hits)) ?? []
  return mapProductsAlgolia(products)
})
</script>

<template>
  <div class="modal-add-product-wrapper w-screen md:w-[80vw]">
    <DialogsAndModalsStandard
      :title="$t('dialogsAndModals.similarProducts.title')"
      fullscreen
      @on-close="closeModal('similar-products')"
    >
      <template #content>
        <!-- Checking for "similarProducts" to have to some content in it to avoid this event not triggering the first time the modal mounts -->
        <!-- TODO: Find a way to do this v-if check inside the component, instead of each instance -->
        <GAItem
          v-if="similarProducts?.length"
          id="similar-product"
          :item="similarProducts"
        >
          <GAViewItemList> </GAViewItemList>
        </GAItem>

        <div
          class="
            relative
            flex flex-col
            gap-4
            p-4
            pb-5
            md:gap-6
            md:px-7
            md:pb-8
            md:pt-6
          "
        >
          <div class="flex flex-col gap-4 md:gap-7">
            <p class="dolphin-bold text-left">
              {{ $t("dialogsAndModals.similarProducts.description") }}
            </p>

            <div class="grid grid-cols-1 gap-6 md:mb-0 md:grid-cols-2">
              <ProductTileOverallSearch
                v-for="product in similarProducts"
                :key="product"
                v-bind="{ ...product, theme: 'outlined-green' }"
                @notification="emit('notification', product.title)"
                @on-close-modal="closeModal('similar-products')"
                :full-width="true"
              />
            </div>
          </div>
        </div>
      </template>
    </DialogsAndModalsStandard>
  </div>
</template>
