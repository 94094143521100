<script setup lang="ts">
import { OtherItemsProps } from "./OtherItems.props"
import { useMenuStore } from "~/stores/menu"

defineProps<OtherItemsProps>()

const menuStore = useMenuStore()
</script>

<template>
  <div class="other-links py-5 pl-4">
    <div class="flex flex-wrap justify-start gap-y-7">
      <div
        v-for="(link, key) in links"
        :key="key"
        @click="menuStore.openCloseSidebar(false)"
        class="relative flex w-full flex-wrap justify-between pr-4"
      >
        <UtilsButton
          :text="link.label"
          theme="ghost-green"
          :path="link.path"
          class="
            custom-btn-pig-semibold-link custom-btn-no-padding custom-btn-start
            z-[1]
            w-full
          "
        />
        <UtilsIcon
          name="ChevronRight.svg"
          class="absolute right-4 top-0 z-0 h-6 w-6"
        />
      </div>
    </div>
  </div>
</template>
