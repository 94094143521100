<script setup lang="ts">
import { CardProps } from "./Card.props"
import { formatMoney } from "~/utils/formatters"
import type { GA4Events } from "nuxt-ga4"
import { addToCartPosition } from "~/utils/constants"
import { isGreaterThan } from "~/utils/productPrice"

const props = withDefaults(defineProps<CardProps>(), {
  quantity: 0,
  oldPrice: 0,
  discountPercetage: 0
})

const { addToCart } = useCart()

const emit =
  defineEmits<{
    (e: "onClose"): void
    (
      e: "onAddToCart",
      payload: { id: string; quantity: number; productCode: string }
    ): void
    (
      e: "onRemoveFromCart",
      payload: { id: string; quantity: number; productCode: string }
    ): void
  }>()

/** Define single GA4 item */
const GAItem = computed<GA4Events["custom_event"]>(() => {
  const promotion = props.promotions?.[0] ?? null
  return {
    item_id: props.productCode ?? "",
    item_name: props.title ?? "",
    index: props.position ?? 0,
    item_list_name: props.mainCategoryName ?? "",
    item_category: props.mainCategoryName ?? "",
    price: props.currentPrice?.toString() ?? "",
    discount: promotion?.text ?? "",
    available: props.inStock ? "si" : "no",
    quantity: 1
  }
})

const handleAddToCart = () => {
  const { sendEventAddToCart } = useGA4SiteEvents()

  addToCart(
    {
      sku_code: props.productCode,
      metadata: {
        product_image: props?.productImage!,
        slug: props?.path ?? "",
        price: props.currentPrice,
        oldPrice: props.oldPrice,
        inStock: props.inStock,
        expirationDate: props?.expirationDate ?? "",
        unitType: props?.unitType ?? "",
        isDeductable: props?.isDeductable ?? false,
        ga4Item: GAItem.value ?? {}
      }
    },
    1
  )

  sendEventAddToCart(GAItem.value, {
    currentPrice: props.currentPrice?.toString() ?? "",
    position: addToCartPosition.miniCart
  })
}

const navigating = ref(false)

const goToProductPage = (path: string) => {
  if (navigating.value) return
  navigating.value = true

  nextTick(() => {
    emit("onClose")
    navigateTo(path)
  })
}
</script>

<template>
  <!-- TODO: Add integration with Algolia events -->
  <div
    @click="() => (path ? goToProductPage(path) : null)"
    :disabled="isFreeGift"
    class="
      product_tile-cart_preview-card
      flex
      transform-gpu
      cursor-pointer
      rounded
      bg-white
      drop-shadow-01
      transition
      hover:drop-shadow-02
    "
  >
    <div class="w-[98px] flex-none py-4">
      <div
        v-if="productImage && productImage.altText !== 'Fallback Image'"
        class="relative h-full"
      >
        <UtilsWrapperImage
          :key="productCode"
          v-bind="productImage"
          class="wrapper-image-fit-contain absolute inset-0"
          sizes="98px md:98px lg:98px"
        />
      </div>
      <div v-else-if="isFreeGift" class="flex h-full justify-center px-4">
        <UtilsIcon name="FreeGift.svg" class="h-full" />
      </div>
      <div v-else class="relative h-full">
        <UtilsWrapperImage
          v-bind="fallbackImage"
          :key="productCode"
          class="wrapper-image-fit-contain absolute inset-0"
        />
      </div>
    </div>

    <div class="flex-1 p-2 pl-4">
      <a v-if="title" :href="path ?? '#'" class="pointer-events-none">
        <p class="mouse-bold mb-3">
          <span v-if="isFreeGift" class="uppercase text-orange-main">{{
            $t("productTile.cartPreview.card.freeGift")
          }}</span>
          {{ title }}
        </p>
      </a>

      <p v-if="quantity" class="mouse mb-3 text-slate-main">
        {{ $t("productTile.cartPreview.card.quantity") }}: {{ quantity }}
      </p>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <BadgeProduct
            v-if="discountPercetage && discountPercetage > 0 && !isFreeGift"
            class="mr-2"
            theme="PROMO"
            :text="`-${discountPercetage}%`"
          />
          <span
            v-if="isFreeGift"
            class="
              snail-bold
              mr-2
              inline-block
              rounded-full
              bg-positive-30
              px-2
              py-1
              uppercase
            "
            >{{ $t("productTile.cartPreview.card.freeGift") }}</span
          >
          <span v-if="currentPrice" class="beaver-bold text-black-800">
            {{ formatMoney(isFreeGift ? 0 : currentPrice) }}
          </span>
          <span
            v-if="!isFreeGift && isGreaterThan(oldPrice, currentPrice)"
            class="snail ml-2 text-black-40 line-through"
          >
            {{ formatMoney(oldPrice) }}
          </span>
        </div>

        <div v-if="!isFreeGift" class="cursor-pointer text-green-main">
          <UtilsIcon
            name="Trash.svg"
            color="green-main"
            v-if="quantity"
            class="h-6 w-6"
            @click.prevent.stop="
              emit('onRemoveFromCart', { id, quantity, productCode })
            "
          />
          <span
            v-else
            class="beaver-medium underline"
            @click.prevent.stop="handleAddToCart()"
          >
            {{ $t("productTile.cartPreview.card.add") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
