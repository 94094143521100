<script setup lang="ts">
import { ButtonProps } from "~/components/Utils/Button.props"
import { SuggestionsProps } from "~/components/Search/Utils/Suggestions.props"
import {
  ProductAlgolia,
  mapProducts,
  mapProductsAlgolia
} from "~/utils/mapProducts"
import type { WikiArticlePageContent } from "~/types/Algolia"
import type { ButtonWithIcon } from "~/components/Search/Utils/Insights.props"
import { customPaths } from "~/utils/constants"
import { addSearchTermIntoCookie } from "~/utils/cookies/searchCookies"
import { NotificationAlertProps } from "~/components/NotificationAlert.props"
import {
  AisInstantSearch,
  AisRefinementList,
  AisConfigure,
  AisHits,
  AisSearchBox,
  AisIndex,
  AisStateResults
  // @ts-ignore
} from "vue-instantsearch/vue3/es/index.js"
import { onClickOutside } from "@vueuse/core"
import { checkEnvSite } from "~/server/utils/getAlgoliaConfig"
import { debounce } from "lodash-es"
import { hitsViewed } from "~/utils/algolia"
import { PRODUCTS_VIEWED_SEARCHBAR } from "~/config/algolia-insights"
import { buildExcludeFilter } from "~/utils/algolia"

const { t } = useI18n()
const route = useRoute()
const isLogged = useIsLogged()
const { notifications, addNotification, removeNotification } = useNotification()
const lastQueries = useStatefulCookie("lastQueries", {
  maxAge: 2592000,
  default: () => []
})

const indexName = ref(`TOP_PRODUCTS${checkEnvSite}`)
const indexMagazineArticlesName = ref(`TOP_MAGAZINE_ARTICLES${checkEnvSite}`)
const indexWikiArticlesName = ref(`TOP_WIKI_ARTICLES${checkEnvSite}`)

const productsSearched = ref()

const mappingProducts = (hits: ProductAlgolia[]) => {
  const productsMapped = mapProductsAlgolia(hits)
  productsSearched.value = productsMapped.map(
    (product: ProductAlgolia) => `product_${product.productCode}`
  )
  return productsMapped
}

const searchClient = {
  ...useAlgoliaRef(),
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: "",
          params: ""
        }))
      })
    }

    return useAlgoliaRef().search(requests)
  }
}

const debounceEventsAlgolia = useDebounceFn(async () => {
  if (productsSearched.value && productsSearched.value.length > 0) {
    hitsViewed(
      "viewedObjectIDs",
      indexName.value,
      PRODUCTS_VIEWED_SEARCHBAR,
      productsSearched.value
    )
  }
}, 1500)

const searchFunction = async (helper: any) => {
  if (helper.state.query && helper.state.query.length < 3) {
    return
  }
  debounceEventsAlgolia()
  const data = helper.setQueryParameter("clickAnalytics", true).search()
}
const emit = defineEmits<{ (e: "onClose"): void }>()

const handleClose = () => {
  notifications.value.splice(0, notifications.value.length)

  emit("onClose")
}

const { isOpen } = useUIState("focus-search")
const { switchState: hasAddedFromAlgoliaSearchBar } = useUIState(
  "added-from-algolia-searchbar"
)

const sharedSearchStatus = useState("SEARCH_STATE")

const queryState = useState("searchbar-query-state", () => "")

const { switchState: showSearchPopup } = useUIState("focus-search")

watch(queryState, () => {
  showSearchPopup(true)
})

watch(
  () => route.fullPath,
  (to, from) => {
    if (to !== from) {
      showSearchPopup(false)
    }
  }
)

// Get popular items
const { data: popularItems } = await useFetch(
  "/api/algolia/getPopularItems/6",
  {
    transform: (res) => mapProducts(res.hits, res.queryID, "ALGOLIA")
  }
)

// Suggestions
type Refinement = {
  value: string
  label: string
  path: string
  isRefined: boolean
  count: number
}

const brandSlugList = ref<string[]>([])

const transformBrandSlug = (slugList: Refinement[]) => {
  brandSlugList.value = slugList.map((slug) => slug.value)

  return brandSlugList.value
}

const transformBrands = (refinements: Refinement[]): ButtonProps[] => {
  return refinements.map((refinement, i) => ({
    text: refinement.label,
    isSelectable: false,
    path: `/b-${brandSlugList?.value[i]}`
  }))
}

const transformSuggestions = (refinements: Refinement[]): ButtonProps[] => {
  return refinements.map((refinement) => ({
    text: refinement.label,
    isSelectable: false
  }))
}

const transformInsights = (insights: WikiArticlePageContent[], { results }) => {
  const isWikiArticle = results.index === indexWikiArticlesName.value
  const response = [
    {
      insightsList: insights
        .map((insight) => {
          return {
            icon: isWikiArticle ? "books" : "post",
            buttonData: {
              text: insight.title,
              path: isWikiArticle
                ? `${customPaths.wikiRoot}/${insight.category.slug}/${insight.slug}`
                : `${customPaths.magazineAllPosts}/${insight.slug}`
            }
          } as ButtonWithIcon
        })
        .slice(0, isWikiArticle ? 2 : 1),
      moreInsights:
        results.nbHits > (isWikiArticle ? 2 : 1)
          ? {
              text: isWikiArticle
                ? t("searchbar.moreWiki")
                : t("searchbar.moreMagazine"),
              path: isWikiArticle
                ? customPaths.wikiRoot
                : customPaths.magazineRoot
            }
          : undefined
    }
  ]
  return response
}

const { data: topQueries } = useLazyFetch(`/api/algolia/getQuerySuggestions`, {
  server: false
})

const suggestionQueries = computed<SuggestionsProps["suggestions"]>(() => {
  return isLogged.value && lastQueries.value.length > 0
    ? lastQueries.value.map((query: string) => ({
        text: query
      }))
    : topQueries?.value?.hits?.map((hit) => ({
        text: hit?.query
      }))
})

const labelSuggestions = computed(() => {
  return lastQueries.value && lastQueries.value.length > 0 && isLogged.value
    ? t("searchbar.labelLastSearchs")
    : t("searchbar.popularSearch")
})

const algoliaSearchbarQueryState = useState("searchbar-query-state")
const applyQuery = (text: string) => {
  algoliaSearchbarQueryState.value = text
  handleTabChange(0) // Force products tab
  lastQueries.value = addSearchTermIntoCookie(lastQueries.value, text)
}

const bannerMenu = {
  bgImageLarge: getImageData({
    altText: t("homepage.welcomeModal.title"),
    url: "/i5oh5hzamkwo/24jIqN36sXU0vl1vPwWEZa/7cfa9a3197776c9e0d8d09ddb8e7d0a9/banner-menu.svg",
    width: 1800,
    height: 200
  }),
  title: t("searchbar.notLoggedBannerTitle"),
  text: t("searchbar.notLoggedBannerDescription")
}

const showMostRequested = () => {
  showSearchPopup(false)
  navigateTo(customPaths.mostSold)
}

const mobileTabs = computed(() => {
  return {
    tabsList: [
      {
        text: "Prodotti"
      },
      {
        text: "Marche"
      },
      {
        text: "Sintomi"
      },
      {
        text: "Approfondimenti"
      }
    ]
  }
})

const mobileTabsSelected: globalThis.Ref<number | undefined> = ref(undefined)

const handleTabChange = (index: number) => {
  mobileTabsSelected.value = index
}

const handleAddToCartNotification = (productName: string) => {
  hasAddedFromAlgoliaSearchBar(true)

  const notification: NotificationAlertProps = {
    id: Math.random(),
    type: "success",
    notificationAlertText: t(
      "dialogsAndModals.addToCart.notificationAddToCart",
      { productName: productName }
    ),
    isWithCloseIcon: true
  }

  addNotification(notification)
}

/**
 * GTM Search Events
 */
const { event, sendEvent } = useGAEvent(
  `track_view_search_results`,
  "custom_event"
)
/**
 * @description On clicking on the search cta
 * @param {string} searchTerm
 * @param {number} searchResultsNumber
 */
const ga4EventViewSearchResults = (
  searchTerm: string,
  searchResultsNumber: number
) => {
  showSearchPopup(false)
  event.value = {
    event: "custom_event",
    action: "view_search_results",
    search_term: searchTerm,
    search_results_number: searchResultsNumber
  }

  lastQueries.value = addSearchTermIntoCookie(lastQueries.value, searchTerm)
  sendEvent({ clearEcommerce: false })
}
const searchbarModal = ref(null)

const isClosable = ref<boolean>(false)

onClickOutside(searchbarModal, () => {
  if (isClosable.value) handleClose()
})

onKeyStroke(
  "Escape",
  (e) => {
    if (isClosable.value) handleClose()
  },
  { dedupe: false }
)

watch(isOpen, (value) => {
  if (value && !isClosable.value) {
    useTimeoutFn(() => {
      isClosable.value = true
    }, 300)
  } else isClosable.value = false
})

let debouncedRefine: any

const debounceRefine = (refine: any, milliseconds: number) => (val) => {
  if (!debouncedRefine) {
    debouncedRefine = debounce(refine, milliseconds)
  }
  debouncedRefine(val)
}
</script>

<template>
  <div
    @keyup.enter="handleClose"
    class="
      search-searchbar_focused
      customHeight
      w-full
      overflow-y-auto
      overscroll-none
      md:h-auto
      md:overscroll-auto
    "
  >
    <div
      class="
        wrapper-notifications
        padded
        absolute
        top-28
        z-[999999]
        flex
        w-full
        flex-col
        gap-2
        md:gap-4
      "
    >
      <TransitionGroup name="opacity">
        <NotificationAlert
          class="flex justify-end"
          v-for="notification in notifications"
          :key="notification.id"
          v-bind="notification"
          @on-close="removeNotification(notification.id)"
        />
      </TransitionGroup>
    </div>

    <AisInstantSearch
      :index-name="indexName"
      :search-client="searchClient"
      :search-function="searchFunction"
      class="h-full md:h-auto"
    >
      <AisConfigure
        :hits-per-page.camel="6"
        :ruleContexts="buildExcludeFilter({ excludeOutOfMarket: true })"
      />

      <div class="flex !h-fit flex-col md:block" ref="searchbarModal">
        <div
          class="
            md:grid-standard
            flex
            h-[60px]
            shrink-0
            items-center
            gap-1
            bg-white
            shadow-01
            md:relative
            md:h-auto
            md:bg-tiffany-10
            md:py-9
          "
        >
          <UtilsButton
            theme="ghost-black"
            class="md:hidden"
            @click="handleClose"
          >
            <template #icon>
              <UtilsIcon name="ArrowLeft.svg" class="h-6 w-6" />
            </template>
          </UtilsButton>
          <div class="flex-1 pr-2 md:col-span-8 md:col-start-3">
            <AisSearchBox>
              <template v-slot="{ currentRefinement, refine }">
                <SearchSearchbar
                  v-bind="{
                    name: 'main-search',
                    label: 'Search label',
                    placeholder: $t('searchbar.placeholder'),
                    isPlaceholderAnimated: false,
                    disabled: false,
                    showTextIcon: true
                  }"
                  :trigger-focus="isOpen"
                  :model-value="currentRefinement"
                  @update:model-value="
                    (val) => debounceRefine(refine, 200)(val)
                  "
                  :force-query="queryState"
                />
              </template>
            </AisSearchBox>
          </div>
          <UtilsIcon
            name="Close.svg"
            class="
              ld:right-[228px]
              absolute
              right-[71px]
              top-5
              hidden
              h-6
              w-6
              cursor-pointer
              md:block
            "
            @click="handleClose"
          />
        </div>

        <div class="h-full md:h-auto">
          <AisStateResults>
            <template v-slot="{ state: { query }, results: { hits, nbHits } }">
              <div
                v-if="query.length === 0"
                class="grid-standard bg-white py-4 md:pt-6"
              >
                <SearchUtilsSuggestions
                  v-if="topQueries?.hits?.length"
                  class="
                    col-span-full
                    mb-6
                    md:col-span-8 md:col-start-3
                    md:mb-16
                  "
                  :title="labelSuggestions"
                  :suggestions="suggestionQueries"
                  @click="applyQuery($event)"
                />
                <BannerMenu
                  v-if="!isLogged"
                  v-bind="bannerMenu"
                  class="col-span-full md:col-span-8 md:col-start-3 md:mb-16"
                />
                <div v-else class="col-span-full h-48 md:mb-16"></div>
              </div>

              <div v-show="query.length > 0">
                <div
                  :class="[
                    'md:grid-standard hidden bg-white',
                    { 'py-6 md:pb-8': hits.length > 0 }
                  ]"
                >
                  <div class="col-span-4 space-y-10" v-show="hits.length > 0">
                    <AisRefinementList
                      attribute="brand.name"
                      :transform-items="transformBrands"
                    >
                      <template v-slot="{ items: brands }">
                        <SearchUtilsSuggestions
                          v-bind="{
                            title: $t('searchbar.brands'),
                            suggestions: brands
                          }"
                        />
                      </template>
                    </AisRefinementList>

                    <AisRefinementList
                      attribute="brand.slug"
                      class="hidden"
                      :transform-items="transformBrandSlug"
                    />
                    <AisRefinementList
                      attribute="symptoms"
                      :transform-items="transformSuggestions"
                    >
                      <template v-slot="{ items: symptoms }">
                        <SearchUtilsSuggestions
                          v-bind="{
                            title: $t('searchbar.symptoms'),
                            suggestions: symptoms
                          }"
                          @click="applyQuery($event)"
                        />
                      </template>
                    </AisRefinementList>

                    <div>
                      <AisIndex :index-name="indexWikiArticlesName">
                        <AisHits :transform-items="transformInsights">
                          <template v-slot:item="{ item }">
                            <SearchUtilsInsights
                              v-show="item.insightsList.length"
                              v-bind="item"
                              class="mb-7"
                              :title="$t('searchbar.insights')"
                            />
                          </template>
                        </AisHits>
                      </AisIndex>

                      <AisIndex :index-name="indexMagazineArticlesName">
                        <AisHits :transform-items="transformInsights">
                          <template v-slot:item="{ item }">
                            <SearchUtilsInsights
                              v-if="item.insightsList.length"
                              v-bind="item"
                            />
                            <div v-else></div>
                          </template>
                        </AisHits>
                      </AisIndex>
                    </div>
                  </div>

                  <AisHits
                    :transform-items="mapProductsAlgolia"
                    class="col-span-8 col-start-5"
                    v-show="hits.length > 0"
                  >
                    <template v-slot="{ items }">
                      <SearchUtilsProducts
                        class="col-span-full"
                        v-bind="{
                          title: $t('searchbar.products'),
                          cards: items,
                          moreProducts: {
                            text: `${t(
                              'searchbar.showAllResults'
                            )} (${nbHits})`,
                            path: `${customPaths.search}?query=${sharedSearchStatus}`
                          }
                        }"
                        @click.prevent="handleClose"
                        @on-add-to-cart="handleAddToCartNotification($event)"
                        @on-click-show-result="
                          ga4EventViewSearchResults(sharedSearchStatus, nbHits)
                        "
                      />
                    </template>
                  </AisHits>
                </div>

                <div class="padded testamelo h-full bg-white md:hidden">
                  <div class="pt-4">
                    <TheTabs
                      v-bind="mobileTabs"
                      v-if="mobileTabs"
                      is-label-uppercase
                      :selected-index="mobileTabsSelected"
                      @change="handleTabChange"
                    >
                      <template #tabs="{ selectedTab }">
                        <div class="py-4">
                          <AisHits
                            :transform-items="mappingProducts"
                            class="col-span-8 col-start-5"
                            v-show="hits.length > 0"
                          >
                            <template v-slot="{ items }">
                              <SearchUtilsProducts
                                v-show="selectedTab === 0"
                                v-bind="{
                                  title: $t('searchbar.products'),
                                  cards: items,
                                  moreProducts: {
                                    text: `${t(
                                      'searchbar.showAllResults'
                                    )} (${nbHits})`,
                                    path: `${customPaths.search}?query=${sharedSearchStatus}`
                                  }
                                }"
                                @click.prevent="handleClose"
                                @on-click-show-result="
                                  ga4EventViewSearchResults(
                                    sharedSearchStatus,
                                    nbHits
                                  )
                                "
                              />
                            </template>
                          </AisHits>
                          <AisRefinementList
                            v-if="selectedTab === 1"
                            attribute="brand.name"
                            :transform-items="transformBrands"
                          >
                            <template v-slot="{ items: brands }">
                              <SearchUtilsSuggestions
                                v-bind="{
                                  title: $t('searchbar.brands'),
                                  suggestions: brands
                                }"
                              />
                            </template>
                          </AisRefinementList>
                          <AisRefinementList
                            attribute="brand.slug"
                            class="hidden"
                            :transform-items="transformBrandSlug"
                          />
                          <AisRefinementList
                            v-if="selectedTab === 2"
                            attribute="symptoms"
                            :transform-items="transformSuggestions"
                          >
                            <template v-slot="{ items: symptoms }">
                              <SearchUtilsSuggestions
                                v-bind="{
                                  title: $t('searchbar.symptoms'),
                                  suggestions: symptoms
                                }"
                                @click="applyQuery($event)"
                              />
                            </template>
                          </AisRefinementList>
                          <div v-if="selectedTab === 3">
                            <AisIndex :index-name="indexWikiArticlesName">
                              <AisHits :transform-items="transformInsights">
                                <template v-slot:item="{ item }">
                                  <SearchUtilsInsights
                                    v-if="item.insightsList.length"
                                    v-bind="item"
                                    class="mb-7"
                                  />
                                  <div v-else></div>
                                </template>
                              </AisHits>
                            </AisIndex>

                            <AisIndex :index-name="indexMagazineArticlesName">
                              <AisHits :transform-items="transformInsights">
                                <template v-slot:item="{ item }">
                                  <SearchUtilsInsights
                                    v-if="item.insightsList.length"
                                    v-bind="item"
                                  />
                                  <div v-else></div>
                                </template>
                              </AisHits>
                            </AisIndex>
                          </div>
                        </div>
                      </template>
                    </TheTabs>
                  </div>
                </div>

                <div
                  v-show="hits.length === 0"
                  class="
                    md:grid-standard
                    col-span-full
                    h-full
                    bg-white
                    px-4
                    pb-6
                    md:mb-14
                    md:h-auto
                    md:pb-8
                    md:pt-6
                  "
                >
                  <div class="col-span-4 space-y-10">
                    <AisIndex index-name="TOP_WIKI_ARTICLES">
                      <AisHits :transform-items="transformInsights">
                        <template #default="{ items }">
                          <SearchUtilsInsights
                            v-for="item in items"
                            v-show="item.insightsList.length"
                            v-bind="item"
                            class="hidden md:mb-7 md:block"
                            :title="$t('searchbar.insights')"
                          />
                          <SearchUtilsProblem
                            :text="$t('searchbar.noResult')"
                          />
                        </template>
                      </AisHits>
                    </AisIndex>
                  </div>
                  <SearchUtilsProducts
                    class="col-span-8 col-start-5 mt-4 md:mt-0"
                    v-bind="{
                      title: $t('searchbar.moreSold'),
                      cards: popularItems,
                      moreProducts: {
                        text: $t('searchbar.moreRequested')
                      },
                      errorDescription: $t('searchbar.noResultsHint')
                    }"
                    @on-click-show-result="showMostRequested"
                  />
                </div>
              </div>
            </template>
          </AisStateResults>
        </div>
      </div>
    </AisInstantSearch>
  </div>
</template>

<style lang="scss" scoped>

.search-searchbar_focused {
  :deep(.ais-Stats, .ais-InstantSearch) {
    @apply h-full;
  }
}

.customHeight {
  height: 90vh;
  height: 100dvh;
}

</style>
