<script setup lang="ts">
import { DynamicMenuContentProps } from "./DynamicMenuContent.props"
import { MenuCategory } from "../Sidebar.props"
import { iconsMap } from "./DynamicMenuContent.iconMapping"
import { useMenuStore } from "~/stores/menu"
import { storeToRefs } from "pinia"

defineProps<DynamicMenuContentProps>()

const emit =
  defineEmits<{
    (e: "select-category", category: { category: MenuCategory }): void
  }>()

const menuStore = useMenuStore()
const { selectedCategory, parentLastActiveItemMenu } = storeToRefs(menuStore)

const selectCategory = (cat: { category: MenuCategory }) => {
  emit("select-category", cat)
}
</script>

<template>
  <nav
    class="flex flex-col items-start gap-3"
    :data-link="categoryLink ?? 'main'"
  >
    <UtilsButton
      v-if="categoryLink"
      :text="
        parent?.category
          ? $t('hamburgerMenu.menuContent.showAllInCatSubcat', {
              category1: parent.category,
              category2: category
            })
          : $t('hamburgerMenu.menuContent.showAllIn', { category: category })
      "
      theme="link-black"
      class="
        custom-btn-no-padding
        cursor-pointer
        px-4
        py-2
        [&_.btn\_\_container]:whitespace-normal
      "
      @click="() => menuStore.openCloseSidebar(false)"
      :to="categoryLink"
    />

    <a
      v-for="item in categories?.slice(
        0,
        showAll || !category ? categories.length : 8
      )"
      :href="item.categoryLink"
      :key="`link-${item.category}`"
      class="flex w-full px-4 py-2"
      :class="[
        `bg-${item.backgroundColor || 'white'} text-${
          item.textColor || 'slate-main'
        }`,
        { hidden: item.categoryLink === '/c-null' }
      ]"
      @click.stop.prevent="
        item.categories && item.categories.length > 0
          ? selectCategory({ category: item })
          : null
      "
    >
      <div class="label-menu flex w-full items-center justify-start">
        <component
          class="mr-1 inline-block h-6 w-6"
          v-if="item.icon"
          :is="iconsMap[item.icon]"
        />

        <p
          class="pig-medium w-full hover:underline"
          v-if="item?.categories?.length > 0"
        >
          {{ item.category }}
        </p>
        <AppLink
          v-else
          class="custom-btn-pig-medium-link w-full no-underline hover:underline"
          :text="item.category"
          @click="() => menuStore.openCloseSidebar(false)"
          :to="item.categoryLink || '#'"
        />
      </div>

      <UtilsIcon
        name="ChevronRight.svg"
        v-if="item.categories && item.categories.length > 0"
        class="h-6 w-6 flex-shrink-0"
      />
      <div
        class="
          menu-layer-container
          absolute
          left-0
          top-0
          z-30
          w-full
          cursor-default
          bg-white
          transition-transform
          duration-300
          ease-in
        "
        :class="[
            {
              'translate-x-full ': !item.isOpen,
              'translate-x-0': item.isOpen as boolean,
            },
          ]"
      >
        <DynamicMenuContent
          v-if="item.categories && item.categories.length > 0"
          class="pb-4"
          :parent="{
            category,
            categoryLink
          }"
          :categories="item.categories"
          :category="item.category"
          :category-link="item.categoryLink"
          :show-all="showAll"
          @select-category="
            ($event) => selectCategory({ category: $event.category })
          "
        />
      </div>
    </a>
  </nav>
</template>
