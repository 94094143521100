<script setup lang="ts">
import { useUserStore } from "~/stores/user"
import { BottomLinksProps } from "./BottomLinks.props"
import { useMenuStore } from "~/stores/menu"
import { customPaths } from "~/utils/constants"
import { navigateToFindOrder } from "~/utils/navigateToFindOrder"
import type { MethodsType } from "~/composables/useGA4SiteEvents"
import { navigateToLoginSignupPage } from "~/utils/loginSignup/loginSignup"

defineProps<BottomLinksProps>()

const userStore = useUserStore()
const menuStore = useMenuStore()

const authToken = useStatefulCookie("authToken")
const refreshToken = useStatefulCookie("refreshToken")
const commerceLayerToken = useStatefulCookie("cmlToken")
const route = useRoute()

const handleCloseSidebar = (iconType: keyof MethodsType | null = null) => {
  menuStore.openCloseSidebar(false)
  // navigateTo() is needed to make sure that query params are updated even if the cta is clicked when already inside the '/contatti' path
  if (route.fullPath.startsWith(customPaths.contactUs)) {
    if (iconType === "customerServiceCall") {
      navigateTo(customPaths.contactUs)
    }
    if (iconType === "customerServiceChat") {
      navigateTo(`${customPaths.contactUs}?help_subject=consulting_pharma`)
    }
  }
}

const handleLogout = async () => {
  authToken.value = null
  refreshToken.value = null
  commerceLayerToken.value = null
  await userStore.logout()
  handleCloseSidebar()
}

const handleOrdersClick = () => {
  navigateToFindOrder()
  handleCloseSidebar()
}

const handleLoginSignup = () => {
  handleCloseSidebar()
  navigateToLoginSignupPage()
}
</script>

<!-- TODO: possible improvement: do not repeat all these handleCloseSidebar()  -->
<template>
  <div class="bottom-links-wrapper">
    <div
      class="
        discounts-code-wrapper
        flex
        justify-start
        bg-strawberry-20
        px-4
        py-3
        text-strawberry-200
        hover:text-strawberry-100
      "
    >
      <UtilsButton
        @click="handleCloseSidebar"
        :text="$t('hamburgerMenu.menuContent.discountCode')"
        class="custom-btn-bg-transparent cursor-pointer"
        :path="customPaths.discounts"
      >
        <template #icon>
          <IconsPricetag class="h-6 w-6 text-strawberry-200" />
        </template>
      </UtilsButton>
    </div>

    <div
      class="
        call-to-action-wrapper
        flex flex-col
        items-start
        bg-slate-main
        px-4
        py-2
      "
    >
      <div v-if="isLogged" class="flex w-full justify-between gap-4 py-4">
        <UtilsButton
          @click="handleCloseSidebar"
          :path="customPaths.profile.root"
          :text="$t('dialogsAndModals.welcome.myProfile')"
          class="
            custom-btn-gap-4
            custom-btn-underlined-link-white
            custom-btn-hover-yellow
          "
        >
          <template #icon>
            <IconsUserLogged class="h-6 w-6" />
          </template>
        </UtilsButton>

        <UtilsButton
          type="button"
          @click="handleLogout"
          order="last"
          :text="$t('hamburgerMenu.menuContent.exit')"
          class="custom-btn-link-white custom-btn-hover-yellow"
        >
          <template #icon>
            <IconsExit class="h-5 w-5" />
          </template>
        </UtilsButton>
      </div>
      <div v-else class="flex items-center gap-1 py-4">
        <UtilsButton
          type="button"
          @click="handleLoginSignup"
          :text="$t('hamburgerMenu.menuContent.logIn')"
          class="
            custom-btn-gap-4
            custom-btn-underlined-link-white
            custom-btn-hover-yellow
          "
        >
          <template #icon>
            <IconsUser class="h-6 w-6" />
          </template>
        </UtilsButton>

        <span class="pig-medium text-white">
          {{ $t("hamburgerMenu.menuContent.or") }}
        </span>

        <UtilsButton
          type="button"
          @click="handleLoginSignup"
          :text="$t('textBlock.utility.register')"
          class="custom-btn-underlined-link-white custom-btn-hover-yellow"
        />
      </div>

      <UtilsButton
        @click="handleOrdersClick"
        :text="$t('hamburgerMenu.menuContent.whereIsMyOrder')"
        class="
          custom-btn-gap-4
          custom-btn-underlined-link-white
          custom-btn-hover-yellow
          py-4
        "
      >
        <template #icon>
          <IconsCar class="h-6 w-6" />
        </template>
      </UtilsButton>
    </div>

    <div
      class="
        support-button-wrapper
        flex flex-col
        items-start
        gap-6
        bg-slate-800
        px-4
        py-5
      "
    >
      <p class="dolphin-bold text-white">
        {{ $t("hamburgerMenu.menuContent.needHelp") }}
      </p>

      <UtilsButton
        @click="handleCloseSidebar('customerServiceCall')"
        :text="$t('hamburgerMenu.menuContent.callClientService')"
        class="custom-btn-underlined-link-white custom-btn-hover-yellow"
        :path="$t('general.supportPhone.link')"
        forceSelf
      />
      <UtilsButton
        @click="handleCloseSidebar('customerServiceCall')"
        :text="$t('hamburgerMenu.menuContent.chatWithClientService')"
        class="custom-btn-underlined-link-white custom-btn-hover-yellow"
        :path="customPaths.contactUs"
      />
      <UtilsButton
        @click="handleCloseSidebar('customerServiceChat')"
        :text="$t('hamburgerMenu.menuContent.askToFarmacist')"
        class="custom-btn-underlined-link-white custom-btn-hover-yellow"
        :path="`${customPaths.contactUs}?help_subject=consulting_pharma`"
      />
      <UtilsButton
        @click="handleCloseSidebar('customerServiceFaq')"
        :text="$t('hamburgerMenu.menuContent.lookFaq')"
        class="custom-btn-underlined-link-white custom-btn-hover-yellow"
        :path="customPaths.faqs"
      />
    </div>
  </div>
</template>
