<script setup lang="ts">
import { useMenuStore } from "~/stores/menu"
import { storeToRefs } from "pinia"
import { MenuCategory } from "../Sidebar.props"

const menuStore = useMenuStore()
const {
  selectedCategory,
  parentLastActiveItemMenu,
  categories,
  activeSideBarOfferts
} = storeToRefs(menuStore)

const showAll = ref(false)
const contentHeight = ref("max-content")

const searchSettings = {
  name: "search",
  label: "Search label",
  placeholder: "Cerca per prodotto, marca o sintomo",
  isPlaceholderAnimated: false,
  modelValue: "",
  disabled: false,
  showTextIcon: true
}

const { event, sendEvent } = useGAEvent(
  `track_navigation_click_`,
  "custom_event"
)

const handleMenuNavigationWithGtmEvent = async (cat: {
  category: MenuCategory
}) => {
  event.value = {
    event: "custom_event",
    action: "navigation_click",
    navigation_type: "side menu",
    label: cat.category?.category ?? ""
  }
  await sendEvent({ clearEcommerce: false })
  setContentHeight(cat?.category?.categoryLink ?? "main")
  menuStore.selectMenuHandler(cat)
}

const setContentHeight = (catLink: string) => {
  const categoryHeight = document.querySelector(
    `nav[data-link='${catLink}']`
  )?.offsetHeight

  contentHeight.value =
    catLink === "main" || !categoryHeight
      ? "max-content"
      : `${categoryHeight - 1}px`
}

const goBackHandler = () => {
  setContentHeight(parentLastActiveItemMenu?.value?.categoryLink ?? "main")
  menuStore.goBack()
}

const expandCategoryHandler = () => {
  showAll.value = true
  nextTick(() =>
    setContentHeight(selectedCategory.value.at(-1)?.categoryLink ?? "main")
  )
}
const categoryTitleClickHandler = () => {
  if (selectedCategory.value.length !== 0) {
    menuStore.openCloseSidebar(false)
  }
}
</script>

<template>
  <div
    v-if="selectedCategory || parentLastActiveItemMenu"
    class="
      content-sidebar-desktop
      relative
      min-h-full
      transition-all
      duration-300
      ease-in-out
    "
    :class="[{ 'pt-10': selectedCategory.length > 0 }]"
  >
    <!-- NAVIGATION BREADCRUMB -->
    <Transition name="slide-left">
      <div
        v-if="selectedCategory"
        class="
          navigation-section
          absolute
          left-0
          top-0
          z-0
          w-full
          bg-slate-80
          px-4
          delay-200
        "
      >
        <UtilsButton
          v-if="parentLastActiveItemMenu"
          :id="parentLastActiveItemMenu.category"
          theme="link-white"
          class="custom-btn-no-underline custom-btn-pig-medium"
          :text="
            parentLastActiveItemMenu.category ??
            $t('hamburgerMenu.menuContent.allCategories')
          "
          stop
          @click="goBackHandler"
        >
          <template #icon>
            <UtilsIcon name="ArrowLeft.svg" color="white" class="h-6 w-6" />
          </template>
        </UtilsButton>
      </div>
    </Transition>

    <SearchSearchbar
      v-if="selectedCategory.length"
      class="mt-2 w-full bg-white px-4 py-2 md:hidden"
      v-bind="searchSettings"
    />

    <!-- TITLE -->
    <div class="title-section-category p-4">
      <UtilsButton
        v-if="selectedCategory"
        :text="
          selectedCategory.length === 0
            ? $t('hamburgerMenu.menuContent.allCategories')
            : selectedCategory.at(-1)?.category
        "
        class="
          custom-btn-hamburger-title
          transition-all
          duration-300
          ease-in-out
        "
        :class="{
          category: selectedCategory.length > 0
        }"
        @click="categoryTitleClickHandler"
        :to="selectedCategory.at(-1)?.categoryLink"
        :is-disabled="!selectedCategory.at(-1)?.categoryLink"
      />
    </div>

    <!-- DYNAMIC CONTENT -->
    <div class="relative overflow-hidden">
      <MenuUtilsDynamicMenuContent
        :categories="categories"
        :show-all="showAll"
        :style="{
          height: contentHeight
        }"
        :class="'transition-all duration-300 ease-in-out'"
        @select-category="($event) => handleMenuNavigationWithGtmEvent($event)"
      />
    </div>

    <div class="flex justify-start">
      <UtilsButton
        v-if="
          !showAll &&
          selectedCategory &&
          selectedCategory.at(-1)?.categories?.length > 8
        "
        :text="$t('hamburgerMenu.menuContent.showMore')"
        theme="link-black"
        class="
          custom-btn-no-underline custom-btn-pig-bold custom-btn-no-padding
          my-5
          cursor-pointer
          px-4
        "
        @click="expandCategoryHandler"
      />
    </div>

    <div
      v-if="activeSideBarOfferts && activeSideBarOfferts.length > 0"
      class="w-full bg-grey-main p-4 pt-6"
    >
      <span class="beaver-medium uppercase text-black-80">{{
        $t("menu.megaMenu.inEvidence")
      }}</span>

      <div class="mt-4 flex flex-col gap-4">
        <CardMenuDefault
          v-for="(card, index) in activeSideBarOfferts"
          :key="`card-${index}`"
          v-bind="card"
          class="!w-full"
          :type="'mini'"
        />
      </div>
    </div>
  </div>
</template>
