<script setup lang="ts">
import { useMenuStore } from "~/stores/menu"
import { SidebarProps } from "./Sidebar.props"
import { storeToRefs } from "pinia"
import { iconsMap } from "./Utils/DynamicMenuContent.iconMapping"
import { navigateToLoginSignupPage } from "~/utils/loginSignup/loginSignup"

defineProps<SidebarProps>()

const { switchState } = useUIState("focus-search")

const handleFocusIn = () => {
  handleClose()
  switchState(true)
}

const emit =
  defineEmits<{
    (e: "onClose"): void
  }>()

const handleClose = () => {
  emit("onClose")
}
const menuStore = useMenuStore()
const isLogged = useIsLogged()
const { selectedCategory } = storeToRefs(menuStore)

const { data: promotion, error: promotionError } = await useFetch(
  "/api/menu/getPromotionsFromHeader"
)

const handleLoginSignup = () => {
  menuStore.openCloseSidebar(false)
  navigateToLoginSignupPage()
}
</script>

<template>
  <div class="sidebar-desktop">
    <!-- SIDEBAR HEADER -->
    <div
      class="
        head-sidebar-desktop
        flex
        items-center
        justify-between
        gap-4
        bg-slate-800
        px-4
        py-3
        text-white
      "
    >
      <div v-if="!isLogged" class="flex items-center gap-1">
        <UtilsButton
          @click="handleLoginSignup"
          :text="$t('hamburgerMenu.menuContent.logIn')"
          class="
            custom-btn-gap-4
            custom-btn-underlined-link-white
            custom-btn-hover-yellow
          "
        >
          <template #icon>
            <UtilsIcon name="User.svg" color="white" class="h-6 w-6" />
          </template>
        </UtilsButton>

        <span class="beaver-medium text-white">
          {{ $t("hamburgerMenu.menuContent.or") }}
        </span>

        <UtilsButton
          @click="handleLoginSignup"
          :text="$t('textBlock.utility.register')"
          class="custom-btn-underlined-link-white custom-btn-hover-yellow"
        />
      </div>

      <DisplayedUsername
        v-else
        class="!bg-inherit !px-0 !py-0 md:bg-none"
        iconColor="white"
        iconClass="md:h-6 md:w-6"
        textClass="md:pig-medium"
      />

      <UtilsIcon
        name="Close.svg"
        color="white"
        @click="handleClose"
        class="h-6 w-6 flex-shrink-0 cursor-pointer"
      />
    </div>

    <SearchSearchbar
      v-if="searchSettings && !selectedCategory.length"
      @on-focus-in="handleFocusIn"
      v-bind="searchSettings"
      class="w-full bg-white px-4 py-2 md:hidden"
    />

    <!-- CONTENT MENU -->
    <MenuUtilsContent />

    <div v-if="!selectedCategory.length">
      <!-- PROMOTIONS -->
      <div
        v-if="!promotionError && !!promotion"
        :key="`link-${promotion.category}`"
        class="
          mt-4
          flex
          w-full
          cursor-pointer
          items-center
          justify-between
          px-4
          py-2
        "
        :style="{
          backgroundColor: `${promotion.backgroundColor}`,
          color: `${promotion.textColor}`
        }"
      >
        <div class="label-menu flex w-full items-center justify-start">
          <component
            class="mr-1 inline-block h-6 w-6"
            v-if="promotion.icon"
            :is="iconsMap[promotion.icon]"
          />
          <AppLink
            @click="menuStore.openCloseSidebar(false)"
            class="pig-medium w-full hover:underline"
            :text="promotion.category"
            :to="promotion.categoryLink || '#'"
          />
        </div>
      </div>

      <!-- OTHER ITEMS AND BOTTOM LINK MENU -->
      <MenuUtilsOtherItems v-if="otherLinks" :links="otherLinks" />
    </div>

    <!-- Bottom links -->
    <MenuUtilsBottomLinks :is-logged="isLogged" />
  </div>
</template>
