<script setup lang="ts">
import { MegaProps } from "./Mega.props"

const props = withDefaults(defineProps<MegaProps>(), {})

const maxColWidth = computed(() => {
  if (typeof props.currentMenuCollection === "number") {
    return props.menuCollection[props.currentMenuCollection]?.length === 4
      ? "small"
      : "medium"
  }
  return "small"
})

const inEvidenceCards = computed(() => {
  if (typeof props.currentMenuCollection === "number") {
    return props.menuOffers[props.currentMenuCollection]
  }
  return []
})
const emit =
  defineEmits<{
    (e: "closeMenuMega"): void
  }>()

const isVertical = computed(() => {
  let toRet = true
  props.menuCollection?.forEach((el) => {
    if (el.list) {
      toRet = false
    }
  })
  return toRet
})
</script>

<template>
  <div class="menu-mega bg-white">
    <div class="flex">
      <!-- Menu -->
      <div
        v-for="(coll, index) in menuCollection"
        v-show="index === currentMenuCollection"
        class="
          padded
          flex
          h-[400px]
          flex-1 flex-row flex-wrap
          items-baseline
          gap-x-6 gap-y-11
          pb-12
          pr-0
          pt-12
        "
        :class="{ 'flex-col gap-y-8': isVertical }"
      >
        <ul
          v-for="(menu, index) in coll"
          :key="`menu-${index}`"
          class="space-y-2"
          :class="[maxColWidth === 'small' ? 'w-[22%]' : 'w-[30%]']"
        >
          <li
            class="beaver-medium md:beaver-bold text-green-main hover:underline"
            @click="$emit('closeMenuMega')"
          >
            <AppLink :to="menu.title.path ? `/c-${menu.title.path}` : '#'">
              {{ menu.title.label }}
            </AppLink>
          </li>
          <li
            v-for="(item, index) in menu.list"
            :key="`submenu-${index}`"
            class="beaver"
            @click="$emit('closeMenuMega')"
          >
            <AppLink
              class="btn-ghost-black inline-block"
              :to="item.path ? `/c-${item.path}` : '#'"
              >{{ item.label }}</AppLink
            >
          </li>
        </ul>
      </div>

      <!-- Evidenza -->
      <div
        v-if="inEvidenceCards.length"
        class="padded flex-shrink bg-grey-main pl-6 pt-12"
      >
        <span class="beaver-bold uppercase text-black-80">{{
          $t("menu.megaMenu.inEvidence")
        }}</span>

        <div
          class="mt-4"
          :class="[
            isEvidenceCardBig
              ? 'grid grid-cols-2 gap-x-20 gap-y-4'
              : 'flex flex-col gap-4'
          ]"
        >
          <CardMenuDefault
            v-for="card in inEvidenceCards"
            v-bind="card"
            :type="isEvidenceCardBig ? 'big' : 'mini'"
            @click="$emit('closeMenuMega')"
          />
        </div>
      </div>

      <!-- MacroCards -->
      <div v-if="!!macroCards" class="padded pb-20 pt-6">
        <span class="pig-medium lg:dolphin-bold">{{ macroCards.title }}</span>
        <div class="mt-7 flex justify-between gap-6 lg:mt-8">
          <CardMenuDefault
            v-for="card in macroCards.cards"
            v-bind="card"
            type="macro"
            @click="$emit('closeMenuMega')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
